<template>
  <div style="display: flex; justify-content: stretch">
    <div style="margin-left: 20px; margin-right: 20px; flex: 1">
      <v-container v-if="!jobIsEmpty">
        <JobHeader />
        <Board
          :savingJob.sync="savingJob"
          :gettingJson.sync="gettingJson"
          :showJson="true"
          :showForecastSwitch="showForecastSwitch"
          v-on:saveBoard="saveJob"
          v-on:getJson="getJson"
        />
      </v-container>
      <!-- snackbar on save -->
      <v-snackbar
        v-model="saveSnackbar"
        :multi-line="true"
        :color="snackbarSuccess ? 'light-green' : 'red'"
      >
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="saveSnackbar = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import JobHeader from "@/components/JobHeader";
import Board from "@/components/Board";
// import propos from "@/services/propos.js";
import common from "@/services/common.js";
import services from "@/services/services.js";

export default {
  components: {
    JobHeader,
    Board,
  },
  beforeCreate() {
    if (this.$store.getters.jobIsEmpty) {
      this.$router.push({ name: "start" });
    }
  },
  computed: {
    ...mapState(["board"]),
    jobIsEmpty() {
      return this.$store.getters.jobIsEmpty;
    },
  },
  data() {
    return {
      showForecastSwitch: window.__env.showForecastSwitch,
      savingJob: false,
      gettingJson: false,
      saveSnackbar: false,
      snackbarSuccess: true,
      snackbarText: "",
    };
  },
  methods: {
    saveJob(column) {
      this.savingJob = true;
      if (this.resourcesFilledIn(column)) {
        // preparing job for saving
        let job = this.prepareJob(column);
        console.log(job);
        // sending job to api
        services
          .saveJob(job)
          .then((response) => {
            // console.log(response);
            if (response.data) {
              this.snackbarSuccess = true;
              this.snackbarText = "Opslaan was succesvol.";
              this.saveSnackbar = true;
              // this.$router.push({ name: "saveSuccessfull" });
            }
          })
          .catch((error) => {
            this.snackbarSuccess = false;
            this.snackbarText =
              "Er was een probleem, gelieve opnieuw te proberen.";
            this.saveSnackbar = true;
            console.log(error.response.data.title);
          })
          .finally(() => {
            this.savingJob = false;
          });
      } else {
        this.snackbarSuccess = false;
        this.snackbarText =
          "Gelieve de foutmeldingen eerst op te lossen alvorens op te slaan.";
        this.saveSnackbar = true;
        this.savingJob = false;
      }
    },
    getJson(column) {
      this.gettingJson = true;
      if (this.resourcesFilledIn(column)) {
        ////// preparing job for exporting
        // let job = this.prepareJob(column);
        ////// sending job to api
        //   propos
        //     .send(job)
        //     .then((response) => {
        //       // console.log(response);
        //       this.gettingJson = false;
        //       if (response.data) {
        //         this.snackbarSuccess = true;
        //         this.snackbarText = "Succesvol opgeslaan en doorgestuurd.";
        //         this.saveSnackbar = true;
        //         // this.$router.push({ name: "saveSuccessfull" });
        //       }
        //     })
        //     .catch((error) => {
        //       this.snackbarSuccess = false;
        //       this.snackbarText =
        //         "Er was een probleem, gelieve opnieuw te proberen.";
        //       this.saveSnackbar = true;
        //       console.log(error.response.data.title);
        //       this.gettingJson = false;
        //     });
        // } else {
        //   this.snackbarSuccess = false;
        //   this.snackbarText =
        //     "Gelieve de foutmeldingen eerst op te lossen alvorens op te slaan.";
        //   this.saveSnackbar = true;
        //   this.gettingJson = false;
        // }
        ////////// preparing the json file
        // console.log(job);
        console.log(this.board);
        // this.prepareFinalTasks(column);
        let json = {
          code:
            this.board.job.jobNumber +
            "-" +
            this.board.job.jobItemNumber +
            "-" +
            this.board.job.routingType.shortName,
          description: this.board.job.description,
          poBatches: [
            {
              code: "1",
              requiredEndDt: this.board.job.requiredEndDt,
              poCells: new Array(),
            },
          ],
        };
        column.tasks.forEach((routingStep) => {
          let resource = routingStep.resources.find(function (res) {
            return res.id === routingStep.resourceId;
          });
          let routingStepTemplate = this.board.columns[0].tasks.find(function (
            rst
          ) {
            return rst.id === routingStep.routingStepTemplateId;
          });
          // console.log(routingStepTemplate);
          let poCell = {
            description: routingStep.cellName,
            cellCode: routingStepTemplate.cellCode,
            sequenceNr: routingStep.sortKey,
            poCellOperations: [
              {
                description: routingStep.operationName,
                operationCode: routingStepTemplate.operationCode,
                sequenceNr: 0,
                poCellOperationResources: [
                  {
                    resourceCode: resource.resourceCode,
                    description: resource.name,
                    setupTimeWMin: 60,
                    runTimeWMin: routingStep.tactTime,
                  },
                ],
              },
            ],
          };
          json.poBatches[0].poCells.push(poCell);
        });

        // propos.authenticate();
        //////// downloading the file
        const fileName = json.code;
        var objectUrl = window.URL.createObjectURL(
          new File([JSON.stringify(json)], fileName, {
            type: ["content-type"],
          })
        );
        common.download(objectUrl, fileName);
      }
      this.gettingJson = false;
    },
    prepareJob(column) {
      let job = this.board.job;
      let routing = {};
      routing.id = column.id;
      routing.name = column.name;
      routing.routingTemplateId = column.routingTemplateId;
      routing.jobNumber = job.jobNumber;
      routing.jobItemNumber = job.jobItemNumber;
      routing.forecast = column.forecast;
      routing.routingTypeId = column.routingTypeId;
      routing.routingType = this.board.routingTypes.find(
        (routingType) => routingType.id == routing.routingTypeId
      );
      routing.jobId = column.jobId;
      this.prepareFinalTasks(column);
      routing.routingSteps = column.tasks;

      job.routings = [];
      job.routings.push(routing);
      // console.log(job);
      return job;
    },
    prepareFinalTasks(column) {
      for (let index = 0; index < column.tasks.length; index++) {
        column.tasks[index].sortKey = index;
      }
    },
    resourcesFilledIn(column) {
      for (let index = 0; index < column.tasks.length; index++) {
        if (
          column.tasks[index].resourceId ==
          "00000000-0000-0000-0000-000000000000"
        ) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="css">
.task-bg {
  background: rgba(0, 0, 0, 0.5);
}
</style>
