<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined class="transparent">
        <v-card-title class="transparent">
          {{ job.jobNumber }}
          {{ job.jobItemNumber ? "-" + job.jobItemNumber : "" }}
          <v-spacer />
          <v-btn icon @click="goHome()">
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle v-if="job.routingType">
          {{ job.routingType.name }} ({{ job.routingType.shortName }})
        </v-card-subtitle>
        <v-card-text>
          <i> {{ job.requiredEndDt }} </i>
          <br />
          {{ job.description }}
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["board"]),
    job() {
      return this.board.job;
    },
  },
  methods: {
    goHome() {
      this.$router.push({ name: "start" });
    },
  },
};
</script>

<style scoped></style>
